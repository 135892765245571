import AMapLoader from '@amap/amap-jsapi-loader'
import { App, reactive } from 'vue'
import { AMapConfig } from '/@/constants/index'

export function useGMapManage () {
  const state = reactive({
    aMap: null, // Map类
    map: null, // 地图对象
    mouseTool: null,
  })

  function changeTileLayer (app:App, type: string, lat?:number, lng?:number) {
    try {
      if (type === 'standard') {
        state.map = new state.aMap.Map('g-container', {
          center: [lng || 114.31919, lat || 22.734039],
          // layers: [new AMap.TileLayer.Satellite()],
          zoom: 15
        })
      } else {
        state.map = new state.aMap.Map('g-container', {
          center: [lng || 114.31919, lat || 22.734039],
          layers: [new state.aMap.TileLayer.Satellite()],
          zoom: 15
        })
      }
      state.mouseTool = new state.aMap.MouseTool(state.map)
      app.config.globalProperties.$map = state.map
      app.config.globalProperties.$mouseTool = state.mouseTool
    } catch (e) {

    }
  }

  async function initMap (container: string, app:App, lat?:number, lng?:number) {
    const AMap = await AMapLoader.load({
      ...AMapConfig
    })
    try {
      state.aMap = AMap
      state.map = new AMap.Map(container, {
        center: [lng || 114.31919, lat || 22.734039],
        // layers: [new AMap.TileLayer.Satellite()],
        zoom: 15
      })
      state.mouseTool = new AMap.MouseTool(state.map)

      // 挂在到全局
      app.config.globalProperties.$aMap = state.aMap
      app.config.globalProperties.$map = state.map
      app.config.globalProperties.$mouseTool = state.mouseTool
      console.log('初始化地图chengg', app.config)
    } catch (e) {
      console.log('初始化地图报错', e)
    }
  }

  async function globalPropertiesConfig (app:App, lat?:number, lng?:number) {
    await initMap('g-container', app, lat, lng)
  }

  return {
    globalPropertiesConfig,
    changeTileLayer,
  }
}
