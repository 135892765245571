export const CURRENT_CONFIG = {

  // license
  appId: '150887', // You need to go to the development website to apply.
  appKey: 'a9d133bed9f3b64d5ff1b89fe246b1e', // You need to go to the development website to apply.
  appLicense: 'M+I5EHaK46H1OTPXdb8FkmqP4+z4xeNnXrDhYeTho9cPjt631gEFft6mKc0qW5Jhfa7+hjBys5pKJBAkc+/21XrKFiFqVUhHYs2rkZ8hdDP2YUh+DNVLWy8jWLEEYLOpOMD6p1jvTfYhokQZyZxSINxbx9ZvJBJvkYX8KE2fBJ0=', // You need to go to the development website to apply.

  // http
  baseURL: 'http://pkg.zxhig.com', // This url must end with "/". Example: 'http://192.168.2.110:6789/'
  // baseURL: 'http://localhost:6789', // This url must end with "/". Example: 'http://192.168.2.110:6789/'
  // baseURL: 'http://192.168.110.39:6789', // This url must end with "/". Example: 'http://192.168.2.110:6789/'
  websocketURL: 'ws://120.76.245.222:6789/api/v1/ws', // Example: 'ws://192.168.2.110:6789/api/v1/ws'
  // websocketURL: 'ws://192.168.110.39:6789/api/v1/ws', // Example: 'ws://192.168.2.110:6789/api/v1/ws'
  // websocketURL: 'ws://localhost:6789/api/v1/ws', // Example: 'ws://192.168.2.110:6789/api/v1/ws'

  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  rtmpURL: 'rtmp://aizefu.com/live/livestream?secret=Lv110@zxhig.com', // Example: 'rtmp://192.168.2.110/live/'
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: '39.108.51.172',
  gbServerPort: '5060',
  gbServerId: '100001',
  gbAgentId: '200001',
  gbPassword: 'zxh123@agent.com',
  gbAgentPort: '15060',
  gbAgentChannel: '1',
  // RTSP
  rtspUserName: 'zxhCloud',
  rtspPassword: 'zxh@Cloud123',
  rtspPort: '8554',
  // Agora
  agoraAPPID: '473d7df7d92e4183b806875f1f2316e5',
  agoraToken: '007eJxTYPh78+l2vTUP0idGdtreCt1zwfjCEjWuqX6h8p7ftiVNDghWYDAxN04xT0kzT7E0SjUxtDBOsjAwszA3TTNMMzI2NEs13XJVM70hkJHh3YXvjIwMEAjiMzIYMTAAAGvdH+k=',
  agoraChannel: '2',
  agoraTokenUrl: 'http://120.76.245.222:4090/agora/api/v1/token/subscriber/',

  // map
  // You can apply on the AMap website.
  // amapKey: 'cabd20fe1c23497d7c5d3c39e801b32e',
  // amapKey: '65b41819e22b92b8af83589212451369'
  amapKey: '75fcff0764c6a103e19c8345f85dae61'

}
